<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-img
          :src="require('@/assets/logo.png')"
          class="center mb-4"
          height="60"
          contain
        ></v-img>
        <v-card outlined class="pa-6 ma-auto" max-width="360px" elevation="4">
          <h1 class="text-center">Reset Password</h1>

          <v-text-field
            :disabled="loading"
            v-model="new_password"
            :type="!view_new_password ? 'password' : 'text'"
            :append-icon="!view_new_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="view_new_password = !view_new_password"
            label="New Password"
            required
          ></v-text-field>
          <v-text-field
            :disabled="loading"
            v-model="new_password_conf"
            :type="!view_new_password_conf ? 'password' : 'text'"
            :append-icon="!view_new_password_conf ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="view_new_password_conf = !view_new_password_conf"
            label="Re-Type New Password"
            required
          ></v-text-field>
          <v-btn
            :loading="loading"
            block
            color="primary"
            class="my-4"
            x-large
            @click="changePassword()"
            >Change Password</v-btn
          >
          <v-btn class="mr-4 my-2" block link :to="{ name: 'Login' }" x-large>
            Back to Login
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="320" persistent>
      <v-card color="success" dark>
        <v-card-title>Success</v-card-title>
        <v-card-text>
          <v-alert type="success">
            {{ alertMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    loading: false,
    alertMessage: "",

    username: "",
    verification_code: "",
    new_password: "",
    new_password_conf: "",
    view_new_password: false,
    view_new_password_conf: false,
  }),

  methods: {
    changePassword() {
      this.loading = true;

      const uri = "auth/forgot/changepassword";

      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("d", this.$route.query.d);
      formData.append("new_password", this.new_password);
      formData.append("new_password_conf", this.new_password_conf);

      this.$axios
        .post(uri, formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          if (res.data.status == "success") {
            this.dialog = true;
            this.alertMessage = res.data.message;
          } else {
            this.showAlert(res.data.status, res.data.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push({ name: "Login" });
    },
  },
};
</script>